import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    aquamarine: '#00EFC1',
    purple: '#1B0A4B',
    teal: '#D9FBF5',
    white: '#FFFFFF',
  },
  styles: {
    global: {
      'body, html': {
        bg: '#f8f4f4',
      },
      'a, a:link, a:hover, a:visited, a:focus, a:active': {
        fontWeight: 600,
        textDecoration: 'none !important',
      },
    },
  },
});

export default theme;
