// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-product-template-tsx": () => import("./../../../src/components/product/template.tsx" /* webpackChunkName: "component---src-components-product-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-currencies-tsx": () => import("./../../../src/pages/currencies.tsx" /* webpackChunkName: "component---src-pages-currencies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-and-conditions-tsx": () => import("./../../../src/pages/legal/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */)
}

