import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import theme from './theme';

interface WrapperProps {
  element: React.ReactNode;
  [key: string]: any;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 10000,
    },
  },
});

const Wrapper: React.FC<WrapperProps> = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} resetCSS>
      {element}
    </ChakraProvider>
  </QueryClientProvider>
);

export default Wrapper;
