import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    aquamarine: '#00EFC1',
    purple: '#1B0A4B',
    teal: '#D9FBF5',
    white: '#FFFFFF',
  },
  styles: {
    global: {
      ':root': {
        '--cp': '1rem',
        '@media screen and (min-width: 576px)': {
          '--cp': '2rem',
        },
        '@media screen and (min-width: 768px)': {
          '--cp': '4rem',
        },
        '@media screen and (min-width: 992px)': {
          '--cp': '6rem',
        },
        '@media screen and (min-width: 1200px)': {
          '--cp': '10rem',
        },
        '@media screen and (min-width: 1440px)': {
          '--cp': '15rem',
        },
      },
      'body, html': {
        bg: '#f8f4f4',
      },
      'a, a:link, a:hover, a:visited, a:focus, a:active': {
        fontWeight: 600,
        textDecoration: 'none !important',
      },
    },
  },
});

export default theme;
